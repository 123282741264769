import {
  AppstoreOutlined,
  CommentOutlined,
  DownOutlined,
  EyeOutlined,
  HeartOutlined,
  RightOutlined,
  SearchOutlined,
  ThunderboltOutlined,
  UpOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Button, Card, Col, Image, Row, Select, Space, Table, Tag } from "antd";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  ReferenceDot,
} from "recharts";
import React, { useEffect, useState } from "react";
import pendc from "../assets/pendc.png";

const { Option } = Select;
const { Column } = Table;

const HomePage = () => {
  const [selectedDuration, setSelectedDuration] = useState("live");
  const [hide, setHide] = useState(true);
  const [openProfile, setOpenProfile] = useState(true);
  const [pageValue, setPageValue] = useState();
  const [minUv, setMinUv] = useState();
  const [pageValueMax, setPageValueMax] = useState();
  const [maxUv, setMaxUv] = useState();

  const handleDurationChange = (value) => {
    setSelectedDuration(value);
  };

  const customBodyStyle = {
    padding: 0, // İç dolguyu kaldırmak için 0 olarak ayarlanıyor
    background: "lightgray", // Arkaplan rengini özelleştirmek için
  };

  const dataGraf = [
    { name: "17:30", uv: 160000, pv: 125000, amt: 40000 },
    { name: "17:31", uv: 160000, pv: 125000, amt: 40000 },
    { name: "17:32", uv: 310000, pv: 280000, amt: 48000 },
    { name: "17:33", uv: 160000, pv: 150000, amt: 60000 },
    { name: "17:34", uv: 350000, pv: 340000, amt: 60000 },
    { name: "17:35", uv: 265000, pv: 255000, amt: 60000 },
    { name: "17:36", uv: 210000, pv: 200000, amt: 60000 },
    { name: "17:37", uv: 320000, pv: 210000, amt: 60000 },
    { name: "17:38", uv: 260000, pv: 250000, amt: 180000 },
    { name: "17:39", uv: 378219, pv: 368219, amt: 60000 },
    { name: "17:40", uv: 180000, pv: 170000, amt: 60000 },
    { name: "17:41", uv: 305000, pv: 295000, amt: 60000 },
    { name: "17:42", uv: 175000, pv: 165000, amt: 60000 },
    { name: "17:43", uv: 225000, pv: 215000, amt: 60000 },
    { name: "17:44", uv: 280000, pv: 270000, amt: 60000 },
    { name: "17:45", uv: 160000, pv: 150000, amt: 60000 },
    { name: "17:46", uv: 352000, pv: 352000, amt: 60000 },
    { name: "17:47", uv: 150446, pv: 140446, amt: 60000 },
    { name: "17:48", uv: 290000, pv: 280000, amt: 60000 },
    { name: "17:49", uv: 190000, pv: 180000, amt: 60000 },
    { name: "17:50", uv: 225000, pv: 215000, amt: 60000 },
    { name: "17:51", uv: 250000, pv: 250000, amt: 60000 },
    { name: "17:52", uv: 210000, pv: 200000, amt: 60000 },
    { name: "17:53", uv: 330000, pv: 320000, amt: 60000 },
    { name: "17:54", uv: 180000, pv: 170000, amt: 60000 },
    { name: "17:55", uv: 360000, pv: 350000, amt: 60000 },
    { name: "17:56", uv: 150000, pv: 140000, amt: 60000 },
    { name: "17:57", uv: 265000, pv: 255000, amt: 60000 },
    { name: "17:58", uv: 300000, pv: 290000, amt: 60000 },
    { name: "17:59", uv: 150000, pv: 140000, amt: 60000 },
  ];

  const dataSource1 = [
    {
      key: "1",
      ticket: "Sunucu Problemleri Hakkında Bilgi",
      date: "27.03.2023 | 21:50",
      priority: "Yüksek",
      condition: "Yanıt Bekleniyor",
    },
    {
      key: "2",
      ticket: "Aktarım Sorunları",
      date: "04.04.2023 | 10:00",
      priority: "Düşük",
      condition: "Yanıt Bekleniyor",
    },
  ];

  const columns = [
    {
      title: "Kabin",
      dataIndex: "cabin",
      key: "cabin",
      width: 32,
    },
    {
      title: "Hat",
      dataIndex: "line",
      key: "line",
      width: 23,
    },
    {
      title: "Port",
      dataIndex: "port",
      key: "port",
      width: 23,
    },
    {
      title: "Tüketim (KW/H)",
      dataIndex: "consumption",
      key: "consumption",
      width: 25,
      render: (text) => <span style={{ color: "orange" }}>{text}</span>,
    },
  ];

  const columnsTicket = [
    {
      title: "Ticket Detayı",
      dataIndex: "ticket",
      key: "ticket",
      render: (text, record) => (
        <div style={{ display: "flex", alignItems: "center" }}>
          <Button
            shape="circle"
            style={{
              border: "none",
              background: "linear-gradient(to right, #FFF5FF, #FFFCDB)",
              marginRight: "5px",
            }}
          >
            <CommentOutlined style={{ color: "orange" }} />
          </Button>
          <div>
            <div>{text}</div>
            <div style={{ fontWeight: 300, color: "gray" }}>
              {text === "Aktarım Sorunları"
                ? "Ticket No: #3519"
                : "Ticket No: #2214"}
            </div>
          </div>
        </div>
      ),
    },
    {
      title: "Tarih",
      dataIndex: "date",
      key: "date",
    },
    {
      title: "Öncelik",
      dataIndex: "priority",
      key: "priority",
      render: (text) => (
        <span style={{ color: text === "Yüksek" ? "red" : "green" }}>
          {text}
        </span>
      ),
    },
    {
      title: "Durum",
      dataIndex: "condition",
      key: "condition",
      render: (text) => (
        <Tag style={{ borderRadius: "20px", border: "none" }} color={"orange"}>
          <span>•</span>
          {text}
        </Tag>
      ),
    },
  ];

  const data = [
    {
      key: "1",
      cabin: "B2-K01",
      line: "M-UPS",
      port: "Toplam",
      consumption: 839.94,
    },
    {
      key: "2",
      cabin: "B2-K02",
      line: "K-UPS",
      port: "Toplam",
      consumption: 330.0,
    },
    {
      key: "3",
      cabin: "B2-K05",
      line: "K-UPS",
      port: "Toplam",
      consumption: 554.62,
    },
    {
      key: "4",
      cabin: "B2-K06",
      line: "M-UPS",
      port: "Toplam",
      consumption: 81.91,
    },
    {
      key: "5",
      cabin: "Salon 1-N3",
      line: "M-UPS",
      port: "Toplam",
      consumption: 839.94,
    },
    {
      key: "6",
      cabin: "Salon 1-S01",
      line: "M-UPS",
      port: "Toplam",
      consumption: 839.94,
    },
    {
      key: "7",
      cabin: "B2-K01",
      line: "M-UPS",
      port: "Toplam",
      consumption: 839.94,
    },
    {
      key: "8",
      cabin: "B2-K01",
      line: "M-UPS",
      port: "Toplam",
      consumption: 839.94,
    },
    {
      key: "9",
      cabin: "B2-K01",
      line: "M-UPS",
      port: "Toplam",
      consumption: 839.94,
    },
    {
      key: "10",
      cabin: "B2-K01",
      line: "M-UPS",
      port: "Toplam",
      consumption: 839.94,
    },
    {
      key: "11",
      cabin: "B2-K01",
      line: "M-UPS",
      port: "Toplam",
      consumption: 839.94,
    },
    {
      key: "12",
      cabin: "B2-K01",
      line: "M-UPS",
      port: "Toplam",
      consumption: 839.94,
    },
    {
      key: "13",
      cabin: "B2-K01",
      line: "M-UPS",
      port: "Toplam",
      consumption: 839.94,
    },
    {
      key: "14",
      cabin: "B2-K01",
      line: "M-UPS",
      port: "Toplam",
      consumption: 839.94,
    },
    {
      key: "15",
      cabin: "B2-K01",
      line: "M-UPS",
      port: "Toplam",
      consumption: 839.94,
    },
    {
      key: "16",
      cabin: "B2-K01",
      line: "M-UPS",
      port: "Toplam",
      consumption: 839.94,
    },
    {
      key: "17",
      cabin: "B2-K01",
      line: "M-UPS",
      port: "Toplam",
      consumption: 839.94,
    },
    {
      key: "18",
      cabin: "B2-K01",
      line: "M-UPS",
      port: "Toplam",
      consumption: 839.94,
    },
  ];

  const onClickEye = () => setHide((prev) => !prev);

  const onClickProfile = () => setOpenProfile((prev) => !prev);

  useEffect(() => {
    const minUv = Math.min(...dataGraf.map((item) => item.uv));
    setMinUv(minUv);
    const findPageObjectMin = dataGraf.find((item) => item.uv === minUv);
    const pageValue = findPageObjectMin?.name;
    setPageValue(pageValue);
    const maxUv = Math.max(...dataGraf.map((item) => item.uv));
    setMaxUv(maxUv)
    const findPageObjectMax = dataGraf.find((item) => item.uv === maxUv);
    const pageValueMax = findPageObjectMax?.name;
    setPageValueMax(pageValueMax)
  }, []);

  return (
    <>
      <div className="page">
        <nav className="menu">
          <div className="logo">
            <Image preview={false} width="40%" height="5vh" src={pendc} />
          </div>
          <div className="buttons">
            <Button
              style={{ padding: "40px" }}
              className="menu-button mainBtn"
              type="primary"
              icon={<AppstoreOutlined style={{ color: "orange" }} />}
            >
              Ana Menü
            </Button>
            <Button
              className="menu-button"
              type="primary"
              icon={<SearchOutlined />}
            >
              Stoklar
            </Button>
            <Button
              className="menu-button"
              type="primary"
              icon={<SearchOutlined />}
            >
              Ürünler
            </Button>
            <Button
              className="menu-button"
              type="primary"
              icon={<SearchOutlined />}
            >
              Siparişler
            </Button>
            <Button
              className="menu-button"
              type="primary"
              icon={<SearchOutlined />}
            >
              Teklifler
            </Button>
            <Button
              className="menu-button"
              type="primary"
              icon={<SearchOutlined />}
            >
              Ticket
            </Button>
            <Button
              className="menu-button"
              type="primary"
              icon={<SearchOutlined />}
            >
              Finans
            </Button>
          </div>
          {/* <div className="profile">
            <img src="profile-image.jpg" alt="Profile" />
          </div> */}
          <div class="container">
            <div class="left-icon">
              <Button
                shape="circle"
                style={{
                  border: "none",
                  background: "linear-gradient(to right, #FFF5FF, #FFFCDB)",
                }}
              >
                <UserOutlined style={{ color: "orange", fontSize:"20px" }} />
              </Button>
            </div>
            <div class="center-content">
              <p style={{ fontSize: "14px", fontWeight: "600" }}>Burak Güneş</p>
              <p style={{ fontSize: "12px" }}>Pentech Bilişim</p>
            </div>
            <div class="right-icon">
              {openProfile === true ? (
                <DownOutlined onClick={onClickProfile} />
              ) : (
                <UpOutlined onClick={onClickProfile} />
              )}
            </div>
          </div>
        </nav>
      </div>

      <Row style={{ margin: "20px 5%" }} gutter={24}>
        <Col span={16}>
          <Row>
            <Col span={7}>
              <Card
                style={{
                  borderRadius: "10px",
                  boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                }}
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div style={{ marginRight: "10px" }}>
                  <Button
                      shape="circle"
                      style={{
                        border: "none",
                        background:
                          "linear-gradient(to right, #FFF5FF, #FFFCDB)",
                      }}
                    >
                      <UserOutlined style={{ color: "#FD7E0D", fontSize:"20px" }} />
                    </Button>
                  </div>
                  <div>
                    <div style={{ fontSize: "10px" }}>Aktif Ürün Sayısı</div>
                    <div>32 Aktif</div>
                  </div>
                </div>
              </Card>
            </Col>
            <Col span={7} push={1}>
              <Card
                style={{
                  borderRadius: "10px",
                  boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                  display: "flex",
                  justifyContent: "space-between",
                  flexDirection: "column",
                  height: "100%",
                }}
              >
                <div>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <div style={{ marginRight: "10px" }}>
                    <Button
                      shape="circle"
                      style={{
                        border: "none",
                        background:
                          "linear-gradient(to right, #FFF5FF, #FFFCDB)",
                      }}
                    >
                      <UserOutlined style={{ color: "#FD7E0D", fontSize:"20px" }} />
                    </Button>
                    </div>
                    <div>
                      <div style={{ fontSize: "10px" }}>
                        Aktif Sipariş Sayısı
                      </div>
                      <div>3 Adet</div>
                    </div>
                  </div>
                  <div style={{ marginTop: "auto" }}>
                    <Button
                      style={{
                        border: "none",
                        boxShadow: "none",
                        background:
                          "linear-gradient(to right, #FFF5ED, #FFFCDB)",
                        color: "#FD7E0D",
                        fontSize: "10px",
                        position: "absolute",
                        bottom: "10px",
                        right: "10px",
                      }}
                    >
                      Şimdi Öde
                    </Button>
                  </div>
                </div>
              </Card>
            </Col>
            <Col span={7} push={2}>
              <Card
                style={{
                  borderRadius: "10px",
                  boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                }}
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div style={{ marginRight: "10px" }}>
                  <Button
                      shape="circle"
                      style={{
                        border: "none",
                        background:
                          "linear-gradient(to right, #FFF5FF, #FFFCDB)",
                      }}
                    >
                      <UserOutlined style={{ color: "#FD7E0D", fontSize:"20px" }} />
                    </Button>
                  </div>
                  <div>
                    <div style={{ fontSize: "10px" }}>Bekleyen Ticket</div>
                    <div>1 Adet</div>
                  </div>
                </div>
              </Card>
            </Col>
          </Row>
          <Row gutter={24} style={{ marginTop: "30px" }}>
            <Col span={23}>
              <Card style={{height:"42vh"}}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginBottom: "20px",
                  }}
                >
                  <Select
                    value={selectedDuration}
                    onChange={handleDurationChange}
                    style={{ width: 220 }}
                  >
                    <Option value="live">Port Kullanım Bilgisi</Option>
                    <Option value="2h">Port Kullanım Bilgisi 1</Option>
                    <Option value="4h">Port Kullanım Bilgisi 2</Option>
                  </Select>
                  <div>
                    <Button
                      style={{
                        borderRadius: "10px 0 0 10px",
                        backgroundColor: "#f8f8f8",
                        color: "black",
                        boxShadow: "none",
                      }}
                      type={selectedDuration === "live" ? "primary" : "default"}
                    >
                      Canlı
                    </Button>
                    <Button
                      style={{ borderRadius: "0", border: "1px solid #EBECF0" }}
                      type={selectedDuration === "2h" ? "primary" : "default"}
                    >
                      2 Saat
                    </Button>
                    <Button
                      style={{ borderRadius: "0", border: "1px solid #EBECF0" }}
                      type={selectedDuration === "4h" ? "primary" : "default"}
                    >
                      48 Saat
                    </Button>
                    <Button
                      style={{ borderRadius: "0", border: "1px solid #EBECF0" }}
                      type={selectedDuration === "4h" ? "primary" : "default"}
                    >
                      1 Ay
                    </Button>
                    <Button
                      style={{
                        borderRadius: "0 10px 10px 0",
                        border: "1px solid #EBECF0",
                      }}
                      type={selectedDuration === "4h" ? "primary" : "default"}
                    >
                      1 Yıl
                    </Button>
                  </div>
                </div>
                <div style={{ width: "100%", height: "250px" }}>
                  <ResponsiveContainer>
                    <AreaChart
                      data={dataGraf}
                      margin={{
                        top: 10,
                        right: 30,
                        left: 0,
                        bottom: 30,
                      }}
                      style={{fontSize:"12px"}}
                    >
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="name" interval={0} angle={90} dy={15} />
                      <YAxis />
                      <Tooltip />
                      <Area
                        type="linear"
                        dataKey="amt"
                        stackId="1"
                        stroke="red"
                        fill="#d5f0ef"
                      />
                      <Area
                        type="linear"
                        dataKey="pv"
                        stackId="1"
                        stroke="black"
                        fill="#d5f0ef"
                      />
                      <Area
                        type="linear"
                        dataKey="uv"
                        stackId="1"
                        stroke="green"
                        fill="#d5f0ef"
                      />
                      <ReferenceDot
                        x={pageValue}
                        y={minUv+200000}
                        fill="green"
                        stroke="none"
                        r={4}
                        label={"Min :" + minUv + " kbit/s"}
                        className="custom-reference-dot"
                      ></ReferenceDot>
                      <ReferenceDot
                        x={pageValueMax}
                        y={maxUv+420000}
                        fill="green"
                        stroke="none"
                        r={4}
                        label={"Max :" + maxUv + " kbit/s"}
                        className="custom-reference-dotMax"
                      ></ReferenceDot>
                      <ReferenceDot
                        x={"17:40"}
                        y={minUv+80000}
                        fill="black"
                        stroke="none"
                        r={4}
                      ></ReferenceDot>
                      <ReferenceDot
                        x={"17:39"}
                        y={maxUv+40000}
                        fill="black"
                        stroke="none"
                        r={4}
                      ></ReferenceDot>
                      <ReferenceDot
                        x={"17:30"}
                        y={40000}
                        fill="red"
                        stroke="none"
                        r={4}
                      ></ReferenceDot>
                      <ReferenceDot
                        x={"17:38"}
                        y={170000}
                        fill="red"
                        stroke="none"
                        r={4}
                      ></ReferenceDot>
                      {/* <ReferenceDot
                        x={pageValue}
                        y={minUv+200000}
                        fill="green"
                        stroke="none
                        r={4}
                        label={"Min :" + minUv + " kbit/s"}
                        className="custom-reference-dot"
                      ></ReferenceDot>
                      <ReferenceDot
                        x={pageValueMax}
                        y={maxUv+420000}
                        fill="green"
                        stroke="none"
                        r={4}
                        label={"Max :" + maxUv + " kbit/s"}
                        className="custom-reference-dotMax"
                      ></ReferenceDot> */}
                    </AreaChart>
                  </ResponsiveContainer>
                </div>
              </Card>
            </Col>
          </Row>
          <Row gutter={24} style={{ marginTop: "2%" }}>
            <Col span={23}>
              <Card
                className="custom-antd-table"
                bodyStyle={customBodyStyle}
                title="Ticket Listesi"
                extra={
                  <Button
                    style={{ border: "none", boxShadow: "none" }}
                    size="small"
                  >
                    Tümünü Gör <RightOutlined />
                  </Button>
                }
              >
                <div className="custom-table-container">
                  <Table
                    style={{ width: "100%" }}
                    className="pad"
                    dataSource={dataSource1}
                    pagination={false}
                    columns={columnsTicket}
                  ></Table>
                </div>
              </Card>
            </Col>
          </Row>
        </Col>
        <Col span={8}>
          <Row gutter={24}>
            <Col span={24}>
              <Card
                style={{
                  background: "linear-gradient(to right, #FFF5FF, #FFFCDB)",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div>
                    <div>
                      Bakiyem <EyeOutlined onClick={onClickEye} />
                    </div>
                    <div style={{ fontWeight: 800 }}>
                      {hide === true ? "₺ *********" : "₺ 35.738,00"}
                    </div>
                  </div>
                  <Button
                    style={{
                      border: "none",
                      boxShadow: "none",
                      backgroundColor: "#FD7E0D",
                      color: "white",
                    }}
                  >
                    Bakiye Yükle
                  </Button>
                </div>
              </Card>
            </Col>
          </Row>
          <Row gutter={24} style={{ marginTop: "20px" }}>
            <Col span={24}>
              <Card>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div style={{ textAlign: "center" }}>
                    <Button
                      shape="circle"
                      style={{
                        border: "none",
                        background:
                          "linear-gradient(to right, #FFF5FF, #FFFCDB)",
                      }}
                    >
                      <UserOutlined style={{ color: "orange" }} />
                    </Button>
                    <div>Ticket Oluştur</div>
                  </div>
                  <div style={{ textAlign: "center" }}>
                    <Button
                      shape="circle"
                      style={{
                        border: "none",
                        background:
                          "linear-gradient(to right, #FFF5FF, #FFFCDB)",
                      }}
                    >
                      <HeartOutlined style={{ color: "orange" }} />
                    </Button>
                    <div>Ziyaretler</div>
                  </div>
                  <div style={{ textAlign: "center" }}>
                    <Button
                      shape="circle"
                      style={{
                        border: "none",
                        background:
                          "linear-gradient(to right, #FFF5FF, #FFFCDB)",
                      }}
                    >
                      <CommentOutlined style={{ color: "orange" }} />
                    </Button>
                    <div>Kargolar</div>
                  </div>
                </div>
              </Card>
            </Col>
          </Row>
          <Row gutter={24} style={{ marginTop: "20px" }}>
            <Col span={24}>
              <Card style={{ height: "490px" }}>
                <Row style={{ marginBottom: "10px" }}>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <ThunderboltOutlined
                      style={{
                        fontSize: "24px",
                        marginRight: "10px",
                        color: "orange",
                      }}
                    />
                    <div>Elektrik Tüketimi</div>
                  </div>
                  <Select
                    defaultValue="2023-4"
                    style={{ width: 120, position:"absolute", right:"30px", top:"20px" }}
                  >
                    <Option value="2023-1">2023-1</Option>
                    <Option value="2023-2">2023-2</Option>
                    <Option value="2023-3">2023-3</Option>
                    <Option value="2023-4">2023-4</Option>
                  </Select>
                </Row>
                <Table
                  columns={columns}
                  dataSource={data}
                  pagination={false}
                  scroll={{
                    y: 250,
                  }}
                />
                <div
                  style={{
                    width: "100%",
                    height: "1px",
                    backgroundColor: "orange",
                  }}
                ></div>
                <div>
                  <h2>Genel Toplam: 5.835,53 KW/h</h2>
                  <p style={{ marginTop: "-10px" }}>
                    Veriler 15 dk gecikmelidir
                  </p>
                </div>
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default HomePage;
