import { Button, Col, Form, Image, Input, Row, message } from "antd";
import React from "react";
import img from "../assets/home-banner.jpg";
import pendc from "../assets/pendc.png";
import { Typography } from "antd";
import { useNavigate } from "react-router-dom";
const { Title } = Typography;

const LoginPage = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();

  const handleSubmit = (values) => {
    if (values.mail === "admin@gmail.com" && values.password === "password") navigate("home")
    else message.error("Mail : admin@gmail.com ve Şifre: password");
  };

  return (
    <div className="App">
      <Row gutter={16} style={{ height: "100vh" }}>
        <Col span={12} style={{ height: "100vh", padding: "50px 70px" }}>
          <Row>
            <Image preview={false} style={{ width: "30%", marginBottom: "50px" }} src={pendc} />
          </Row>
          <Row style={{ marginTop: "50px" }}>
            <Title>Pendc'ye Hoşgeldiniz</Title>
          </Row>
          <Row style={{ marginTop: "-30px", marginBottom: "20px" }}>
            <p style={{ color: "gray" }}>
              Kullanıcı bilgileriniz ile giriş yapabilirsiniz.
            </p>
          </Row>
          <Row gutter={24}>
            <Col style={{ width: "51.5%" }}>
              <Form form={form} onFinish={handleSubmit}>
                <Form.Item name="mail" style={{ width: "100%" }}>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <label>E-Posta Adresi</label>
                    <Input placeholder="isminiz@gmail.com" />
                  </div>
                </Form.Item>
                <Form.Item name="password" style={{ width: "100%" }}>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <label>Şifreniz</label>
                    <Input.Password placeholder="Şifrenizi Giriniz" />
                  </div>
                </Form.Item>
              </Form>
            </Col>
          </Row>
          <Row>
            <Button
              style={{
                width: "50%",
                border: "none",
                backgroundColor: "#FD7E0D",
                color: "white",
              }}
              onClick={() => {
                form.submit();
              }}
            >
              Giriş Yap
            </Button>
          </Row>
          <Row>
            <p>
              Bilgilerinizi hatırlamıyor musunuz?
              <Button style={{ border: "none", color: "#FD7E0D" }}>
                Şifremi Unuttum
              </Button>
            </p>
          </Row>
        </Col>
        <Col span={12} style={{ height: "100%" }}>
          <Image preview={false} style={{ width: "100%", height: "100vh" }} src={img} />
        </Col>
      </Row>
    </div>
  );
};

export default LoginPage;
